




























import {
  computed,
  defineComponent,
  toRefs,
  reactive
} from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BlkButton',
  props: {
    variation: {
      type: Number,
      default: 1,
      validator: (n: number) => Number.isInteger(n) && n >= 1 && n <= 5
    },
    ripple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click'],

  setup(props, { emit }) {
    const { variation, ripple, disabled } = toRefs(props)

    const rippleData = reactive({
      show: false,
      left: '',
      top: '',
      size: ''
    })

    const classes = computed(() => ({
      [`blk-button--variation-${variation.value}`]: true,
      [`blk-button--variation-${variation.value}--disabled`]: disabled.value,
      [`blk-button--variation-${variation.value}--non-ripple`]: !ripple.value
    }))

    const handleRipple = ({ pageX, pageY, currentTarget }: MouseEvent) => {
      if (currentTarget === null) return
      const el = currentTarget as HTMLElement
      const { clientWidth, clientHeight } = el

      const { left: offsetLeft, top: offsetTop } = el.getBoundingClientRect()

      const diameter = Math.max(clientWidth, clientHeight)
      const radius = diameter / 2

      rippleData.size = `${diameter}px`
      rippleData.left = `${pageX - scrollX - (offsetLeft + radius)}px`
      rippleData.top = `${pageY - scrollY - (offsetTop + radius)}px`

      rippleData.show = true

      setTimeout(() => (rippleData.show = false), 600)
    }
    const onClick = (event: MouseEvent) => {
      if (ripple.value && !rippleData.show) handleRipple(event)
      emit('click', event)
    }

    return {
      rippleData,
      classes,
      onClick
    }
  }
})
