import gql from 'graphql-tag'
interface tickerType {
  __typename: string
  sequenceNumber: number | string
  debtor: any
  state: any
  classification: any
  name: string
}

interface AssetType {
  ticker: tickerType
}

export interface OfferType {
  assets: AssetType[]
  id: string
  availableShares: number
  closingDate: string
  offerImage: string|null
  finalDate: string
  offerName: string
  openingDate: string
  title: string
  totalValue: number
  totalPaid: number
  totalDistributed: number
  totalReserved: number
  irr: number
  status: string
  minInvestValue: number
  maxInvestValue: number
  increment: number
  hasActiveMetrics: boolean
  amountOfQuotas: number
  isPublic: boolean
  allowedGroups: [string]
  __typename: string
}

export const offersQuery = gql`
  {
    offers {
      __typename
      id
      offerImage
      availableShares
      closingDate
      finalDate
      offerName
      openingDate
      title
      totalValue
      totalPaid
      totalDistributed
      totalReserved
      irr
      status
      minInvestValue
      maxInvestValue
      increment
      hasActiveMetrics
      amountOfQuotas
      isPublic
      allowedGroups
      assets {
        ... on PrecatorioType {
          ticker {
            debtor
            state
            classification
          }
        }
      }
    }
  }
`
