import { OfferType } from '@/queries/offersQuery'

const noSpImgs = 5
const noFederalImgs = 5
const baseImgPath = '/static/images/offers/offers-banners'

const b64ToInt = (value: string) => {
  const alphabet =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  let result = 0
  for (let i = 0, len = value.length; i < len; i++) {
    result *= 64
    result += alphabet.indexOf(value[i])
  }

  return result
}

interface OfferImg {
  jpg: string
  webp: string
  largeJpg: string
  largeWebp: string
}

const _getImg = (imgName: string, useBaseImg = true): OfferImg => {
  const baseImg = useBaseImg ? `${baseImgPath}/${imgName}` : imgName
  return {
    jpg: `${baseImg}.jpg`,
    webp: `${baseImg}.webp`,
    largeJpg: `${baseImg}-large.jpg`,
    largeWebp: `${baseImg}-large.webp`
  }
}

const status2BannerMap: Record<string, string> = {
  preopen: 'maarten-van-den-heuvel',
  open: 'federal1',
  closed: 'jonathan-kemper',
  paid: 'josh-appel',
  '': ''
}

export default function getOfferBannerImg(offer: OfferType): OfferImg {
  const everyDebtor = (debtor: String) =>
    offer.assets?.length &&
    offer.assets.every((asset) => asset.ticker.debtor == debtor)
  const everyState = (state: String) =>
    offer.assets?.length &&
    offer.assets.every((asset) => asset.ticker.state == state)

  if (offer.offerImage !== null) return _getImg(offer.offerImage, false)

  if (everyState('SP'))
    return _getImg(
      'sao-paulo' + ((b64ToInt(offer.id.slice(-8)) % noSpImgs) + 1)
    )

  if (everyDebtor('federal'))
    return _getImg(
      'federal' + ((b64ToInt(offer.id.slice(-8)) % noFederalImgs) + 1)
    )

  return _getImg(status2BannerMap[offer.status] || 'gavel')
}
