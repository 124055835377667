var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"blk-button",class:_vm.classes,attrs:{"disabled":_vm.disabled},on:{"click":_vm.onClick}},[_vm._t("default"),_vm._v(" "),(_vm.loading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.rippleData.show),expression:"rippleData.show"}],staticClass:"ripple",class:{
      'ripple--white': _vm.variation == 1,
      'ripple--primary': _vm.variation != 1
    },style:({
      left: _vm.rippleData.left,
      top: _vm.rippleData.top,
      width: _vm.rippleData.size,
      height: _vm.rippleData.size
    })})],2)}
var staticRenderFns = []

export { render, staticRenderFns }