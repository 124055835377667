<template>
  <!-- based on balko-ui commit fd7802461830d7ddab1eadf345d375376c84fff9 -->
  <p class="blk-card__title m-0"><slot></slot></p>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({ name: 'BlkCardTitle' })
</script>
