


















































































import BlkCard from '@/components/balko-ui/BlkCard.vue'
import BlkCardTitle from '@/components/balko-ui/BlkCardTitle.vue'
import BlkCardOverline from '@/components/balko-ui/BlkCardOverline.vue'
import BlkButton from '@/components/balko-ui/BlkButton.vue'

import { defineComponent, toRefs, computed } from '@nuxtjs/composition-api'
import BlkFallbackedImg from '../BlkFallbackedImg.vue'

const stringWithDefault = {
  type: String,
  default: ''
}
const stringOrDateWithDefault = {
  type: [String, Date],
  default: ''
}
const numberWithDefault = {
  type: Number,
  default: 0
}

export default defineComponent({
  name: 'BlkOfferCard',
  components: {
    BlkCard,
    BlkCardTitle,
    BlkCardOverline,
    BlkButton,
    BlkFallbackedImg
  },
  props: {
    id: stringWithDefault,
    status: stringWithDefault,
    offerName: stringWithDefault,
    title: stringWithDefault,
    irr: numberWithDefault,
    finalDate: stringWithDefault,
    minInvestValue: numberWithDefault,
    openingDate: stringOrDateWithDefault,
    closingDate: stringOrDateWithDefault,
    amountOfQuotas: numberWithDefault,
    availableShares: numberWithDefault,
    increment: numberWithDefault,
    totalPaid: numberWithDefault,
    img: stringWithDefault,
    imgFallback: stringWithDefault
  },
  setup(props) {
    const {
      status,
      openingDate,
      closingDate,
      finalDate,
      minInvestValue,
      amountOfQuotas,
      availableShares,
      increment,
      totalPaid
    } = toRefs(props)

    const isPreopen = computed(() => status.value == 'preopen')
    const isOpen = computed(() => status.value == 'open')
    const isClosed = computed(() => status.value == 'closed')
    const isPaid = computed(() => status.value == 'paid')

    const headerTextDate = computed(() => {
      switch (status.value) {
        case 'preopen':
          return openingDate.value
        case 'closed':
          return closingDate.value
        case 'paid':
          return finalDate.value
        case 'open':
        default:
          return '1970-01-01'
      }
    })

    const lastInfoValue = computed(() => {
      switch (status.value) {
        case 'preopen':
        case 'open':
          return minInvestValue.value
        case 'closed':
          return (
            (amountOfQuotas.value - availableShares.value) * increment.value
          )
        case 'paid':
          return totalPaid.value
        default:
          return NaN
      }
    })

    return {
      headerTextDate,
      lastInfoValue,
      isPreopen,
      isOpen,
      isClosed,
      isPaid
    }
  }
})
