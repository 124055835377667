





















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({ name: 'BlkFallbackedImg' })
export default class extends Vue {
  @Prop() src!: string
  @Prop() fallback!: string

  currentSrc: null | string = this.src

  onErr() {
    this.currentSrc = this.fallback
  }
}
