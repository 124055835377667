import { useContext } from '@nuxtjs/composition-api'
import {
  useQuery as useApolloQuery,
  useMutation as useApolloMutation,
  useResult,
  UseQueryReturn,
  UseMutationReturn
} from '@vue/apollo-composable/dist'
import { whenever } from '@vueuse/core'

import errorHandler from '@/plugins/apollo-error-handler'
// TODO: Type all functions arguments
// import { DocumentNode } from 'graphql'

export { useResult }
export function useQuery(...args: any) {
  //@ts-ignore
  const ans: UseQueryReturn<any, any> = useApolloQuery(...args)
  const context = useContext()

  whenever(ans.error, () =>
    errorHandler({ graphQLErrors: [ans.error.value] }, context)
  )
  return ans
}

export function useMutation(...args: any) {
  //@ts-ignore
  const ans: UseMutationReturn<any, any> = useApolloMutation(...args)
  const context = useContext()

  whenever(ans.error, () =>
    errorHandler({ graphQLErrors: [ans.error.value] }, context)
  )
  return ans
}
